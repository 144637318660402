* {
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
  margin: 0;
  padding: 0;
}

.containerView {
  height: 100%;
}

.containerView.min-dimensions {
  min-width: var(--screen-min-width);
  min-height: var(--screen-min-height);
}

/* for removing underline from all <a> tags and <Link> tags */
a {
  text-decoration: none;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background: transparent;
}

::-webkit-scrollbar-track {
  background: #edece9;
}

::-webkit-scrollbar-thumb {
  background: #d3d1cb;
}

::-webkit-scrollbar-thumb:hover {
  background: #9e9e9e;
}
