.suggestion__tile__container {
  margin: 5px 0;
  display: flex;
  align-items: center;
  padding: 5px 10px;
}

.suggestion__tile__container.hovered {
  background-color: var(--light-transparent-bg-grey);
}

.suggestion__tile__container .details__container {
  justify-content: space-between;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
}

.suggestion__tile__container .details__container .suggestion__left__container {
  display: flex;
  align-items: center;
  height: 100%;
}

.suggestion__left__container .name__and__email__container {
  display: flex;
  flex-direction: column;
  margin: 0 10px;
  height: 100%;
}

.suggestion__tile__container .details__container .suggestion__right__container {
  display: flex;
  align-items: flex-end;
  height: 40px;
}

.suggestion__left__container .name__and__email__container .name,
.suggestion__left__container .name__and__email__container .email,
.suggestion__right__container .phone {
  font-size: var(--font-14);
  color: var(--white);
  font-family: var(--poppins);
  line-height: 20px;
}

.suggestion__left__container .name__and__email__container .name{
  text-align: left;
}
