.acc_del_success_container{
 
  display: flex;
  flex-direction: column;
  border: 1px;
  box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.16);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;
    background: #3C3C58;
    padding: 20px;
    border-radius: 15px;
    text-align: center;
    color: white;
    width: 350px;
    height: 250px;
}
#asdfgr{
    left: 0;
}
.Account_Deleted_Successfully{
    font-size: 29px;
    font-family: var(--gibson-semibold);
}
.otp_text_container{
    font-family: var(--poppins);
    margin-top: 10px;
}
.success_tick_img{
    height: 60px;
    margin: 20px;
}