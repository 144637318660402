.move__and__copy__file__head {
  font-family: var(--gibson-semibold);
  font-size: var(--font-24);
  color: var(--white);
  height: 34px;
  display: flex;
  justify-content: flex-end;
}

.move__copy__file__container__wrapper__inner {
  width: 625px;
  padding: 16px;
  border-radius: 20px;
  background: #2a2e43 0% 0% no-repeat padding-box;
  margin: auto;
  color: white;
}

.move__and__copy__popup__close__icon {
  text-align: right;
  cursor: pointer;
  position: absolute;
}
.move__and__copy__popup__line {
  margin-top: 0px;
  margin-bottom: 10px;
  border-top: 1px solid var(--gray);
}

.move__and__copy__file__popup__row {
  width: 598px;
  display: flex;
  position: relative;
  /* background-color: aquamarine; */
  /* height: 40px; */
  flex-direction: column;
  margin: 0 0 10px 0;
}

.move__and__copy__file__popup__row .move__and__copy__file__popup__name {
  font-family: var(--gibson-semibold);
  font-size: var(--font-16);
  color: var(--white);
}

.move__and__copy__file__popup__content__wrapper {
  min-height: 289px;
  max-width: 593px;
}

.move__and__copy__file__content__head {
  font-family: var(--gibson-semibold);
  font-size: var(--font-16);
}

.move__and__copy__file__content__file__browser {
  min-height: 265px;
  min-width: 593px;
  height: 277px;
  width: 593px;
  background-color: var(--light-blue);
  border-radius: 20px;
  margin-top: 8px;
}

.move__and__copy__file__popup__button__row__wrapper {
  height: 44px;
  display: flex;
  justify-content: flex-end;
  position: relative;
  margin-top: 8px;
}
.move__and__copy__file__popup__back__button {
  width: 80px;
  height: 44px;
  background-color: var(--gray);
  border: none;
  border-radius: 12px;
  color: var(--white);
  font-family: var(--gibson-semibold);
  font-size: var(--font-13);
  margin-right: 12px;
}

.move__and__copy__file__popup__moveorcopy__button {
  width: 80px;
  height: 44px;
  background-color: var(--green);
  border: none;
  border-radius: 12px;
  color: var(--white);
  font-family: var(--gibson-semibold);
  font-size: var(--font-13);
}

.link__sliding__tab__header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 32px;
  position: relative;
}

.link__sliding__tab__header .link__sliding__tab__lhs {
  display: flex;
  align-items: center;
  height: 100%;
  flex: 1;
}

.link__sliding__tab .link__sliding__tab__name {
  font-family: var(--gibson-semibold);
  font-size: var(--font-12);
  color: var(--white);
}

.link__sliding__tab {
  width: 106px;
  height: 32px;
  border-radius: var(--border-radius-12);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 2;
  text-transform: uppercase;
}

.link__sliding__tab__active {
  background-color: var(--dark-orange-gradient-stop);
  border-radius: var(--border-radius-12);
  text-align: center;
  cursor: pointer;
}

.link__sliding__tab__inactive {
  border-radius: var(--border-radius-12);
  text-align: center;
  background-color: var(--copy-link-container-bg-color);
}
