.input__box__and__suggestion__list__container {
  position: relative;
  width: 100%;
  margin-top: 10px;
  min-height: 52px;
}

.input__and__teacher__count__container {
  background-color: var(--white);
  border-radius: var(--border-radius-12);
  padding: 5px 10px;
  position: relative;
  z-index: 11;
}

.suggestion__input__container {
  display: flex;
  flex-wrap: wrap;
  max-height: 120px;
  overflow-y: auto;
  align-content: flex-start;
  scrollbar-width: thin;
}

.auto__suggestion__input {
  width: 100%;
  resize: none;
  height: 30px;
  flex: 1;
  display: flex;
  /* flex-basis: 100%; */
  border: none;
  line-height: 30px;
  color: var(--gray);
  font-size: var(--font-15);
  font-family: var(--gibson-semibold);
  margin: 0 5px;
  overflow-x: scroll;
}

.auto__suggestion__input::-webkit-scrollbar {
  display: none !important;
}

.input__and__teacher__count__container
  .teacher__count__container
  .teacher__count__text {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  font-size: var(--font-11);
  color: var(--gray);
  font-family: var(--poppins-light);
}

.suggestion__list__container {
  background-color: var(--light-blue);
  border-bottom-left-radius: var(--border-radius-12);
  border-bottom-right-radius: var(--border-radius-12);
  max-height: 250px;
  overflow-x: hidden;
  overflow-y: auto;
  margin-top: -10px;
  position: absolute;
  width: 100%;
  z-index: 10;
  /* height: 0px; */
  height: auto;

  padding: 0px;
  transition: all 0.6s ease;
}

.suggestion__list__container.active {
  padding-top: 20px;
  padding-bottom: 5px;
  height: auto;
}

/* placeholder */

.input__and__teacher__count__container .auto__suggestion__input::placeholder {
  color: var(--gray);
}
