.End_Session_Confirmation_Popup_Heading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
}

.End_Session_Confirmation_Popup_Heading img {
    margin: 10px;
}

.End_Session_Confirmation_Popup_Content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Rate_Session_Confirmation_Popup_Stars {
    display: flex;
}
