.delete__file__container__wrapper__inner {
  width: 490px;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 16px;
  background: #2a2e43 0% 0% no-repeat padding-box;
  margin: auto;
  padding: 16px !important;
  color: white;
  border-radius: 20px;
}
.delete_popup_header {
  font-family: var(--gibson-semibold);
  font-size: var(--font-24);
  color: var(--white);
  display: flex;
  height: 34px;
  justify-content: flex-end;
}

.delete__file__popup__close__icon {
  text-align: right;
  cursor: pointer;
  display: flex;
  /* margin: -30px 0px 0 247px; */
  align-items: center;
  /* position: absolute; */
}

.delete__file__popup__row {
  /* width: 249px; */
  width: 100%;
  display: flex;
  position: relative;
  /* background-color: aquamarine; */
  height: 76px;
  flex-direction: column;
}

.delete__file__popup__row .delete__file__popup__name {
  font-family: var(--gibson-semibold);
  font-size: var(--font-16);
  color: var(--white);
}

.delete__file__popup__row .delete__file__popup__size {
  font-family: "PoppinsRegular";
  font-size: 14px;
  color: var(--white);
  opacity: 0.6;
}

.delete__file__popup__row .delete__file__popup__location {
  font-family: var(--poppins-light);
  font-size: 11px;
  color: var(--white);
  opacity: 100%;
}

.delete__file__popup__content__confirmation__container {
  /* background-color: black; */
  color: var(--white);
  display: flex;
  /* height: 108px; */
  /* width: 257px; */
  width: 100%;
  flex-direction: column;
}

.delete__file__popup__confirmation {
  font-family: var(--poppins-semibold);
  font-size: var(--font-14);
  line-height: 22px;
  margin-bottom: 8px;
}

.delete__file__popup__note {
  font-family: var(--poppins-light);
  font-size: var(--font-14);
  color: var(--gray);
  line-height: 22px;
}

.delete__file__popup__button__row__wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  position: relative;
}

.delete__file__popup__back__button {
  width: 80px;
  height: 44px;
  background-color: var(--green);
  border: none;
  border-radius: 12px;
  color: var(--white);
  font-family: var(--gibson-semibold);
  font-size: var(--font-13);
}

.delete__file__popup__delete__button {
  width: 80px;
  height: 44px;
  background-color: var(--red);
  border: none;
  border-radius: 12px;
  color: var(--white);
  font-family: var(--gibson-semibold);
  font-size: var(--font-13);
}

.delete__file__popup__header {
  font-family: var(--gibson-semibold);
  font-size: var(--font-24);
  color: var(--white);
  display: inline;
  margin-bottom: 10px;
  display: flex;
}
