.linkclassroom-miro {
  border: 0px;
  outline: 0px;
  background: transparent;
}

.fullWidthFrame {
  width: 100%;
  height: 100%;
}

.scaledFrame {
  width: calc(100% * (1 / 0.3));
  height: calc(100% * (1 / 0.3));
  transform: scale(0.3);
}

.frameAbsolute {
  position: absolute;
}

.errorMessage {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  align-items: center;
  background-color: var(--white);
  font-size: 1.2em;
}

.frameNoScroll {
  overflow: hidden !important;
}

.frameNoScroll ::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}
