.error_boundary_container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}

.error__text__container {
  position: relative;
  top: -120px;
}

.error__header {
  font-family: var(--gibson-semibold);
  color: var(--light-blue);
  text-align: center;
  font-size: var(--font-40);
}

.error__paras__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: var(--gibson);
  color: var(--dark-grey);
  font-size: var(--font-24);
}

.error__buttons__container {
  display: flex;
  justify-content: center;
  position: relative;
  top: -95px;
  left: -14px;
  width: 647px;
}

.error__buttons__container > div {
  display: flex;
  width: 360px;
  justify-content: space-between;
}

.go__back__button {
  padding: 19px 49px 18px 51px;
  font-size: 15px;
  font-family: var(--gibson-semibold);
  color: white;
  cursor: pointer;
  background-image: linear-gradient(to bottom, var(--light-orange-gradient-start), var(--light-orange-gradient-stop));
  border-radius: 14px;
  text-align: center;
}

.contact__support__button {
  padding: 19px 15px 18px 16px;
  color: white;
  font-size: 15px;
  font-family: var(--gibson-semibold);
  background-color: var(--gray);
  border-radius: 14px;
  text-align: center;
}

.support__link:hover {
  text-decoration: none;
}