.Student_Live_Class_Teacher_Dp_Screen_Container {
  width: 100%;
  height: 100%;
  border-radius: 15px;
  position: absolute;
  z-index: 6;
  top: 0;
  left: 0;
}

.Student_Live_Class_Teacher_Dp_Container_Content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Student_Live_Class_Teacher_Dp_Container_Content .initial__avatar__name {
  /* font-size: var(--teacher-dp-initial-font-size) !important; */
}

.parent-view-user-avatar {
  height: 0;
  position: relative;
}

.Student_Live_Class_Teacher_Dp_Container_Speaker_Name{
  color: white;
  max-width: 80%;
  margin-top: 3px;
  text-align: center;
  font-family: var(--gibson-semibold);
}