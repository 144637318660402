/* General styles */

.student_live_class_popup_background {
  position: fixed;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  z-index: 91;
  top: 0;
  left: 0;
}

.student_live_class_popup_container {
  background-color: var(--dark-blue);
  border-radius: 12px;
  box-shadow: var(--content-header-shadow);
}

.student_live_class_popup_title_font {
  font-family: var(--gibson-semibold);
  font-size: var(--font-24);
  line-height: 29px;
  color: var(--white);
}

.student_live_class_popup_text_font {
  font-family: var(--poppins);
  font-size: var(--font-14);
  line-height: 22px;
  color: rgba(255, 255, 255, 0.68);
}

.student_live_class_popup_button {
  width: 80px;
  height: 44px;
  border-radius: 12px;
  background: #17c8af;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: var(--gibson-semibold);
  font-size: var(--font-13);
  line-height: 16px;
  color: var(--white);
  cursor: pointer;
}

.student_live_class_popup_button_accept {
  background: #17c8af;
}

.student_live_class_popup_button_reject {
  background-image: linear-gradient(#fdbb5c, #ffa015);
}

/* Popup specific styles */

.request_pending_popup_container {
  background-color: var(--dark-blue);
  width: 327px;
  height: 276px;
  border-radius: 12px;
  padding: 25px 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.request_pending_popup_icon {
  margin-bottom: 14px;
}

.request_pending_popup_title {
  text-align: center;
  font-family: var(--gibson-semibold);
  font-size: var(--font-24);
  line-height: 29px;
  color: var(--white);
  margin-bottom: 7px;
}

.request_pending_popup_text {
  text-align: center;
  font-family: var(--poppins);
  font-size: var(--font-14);
  line-height: 22px;
  color: rgba(255, 255, 255, 0.68);
  white-space: pre;
}

.request_pending_popup_button {
  margin-top: 16px;
}

.entry_request_denied_popup_container {
  background-color: var(--dark-blue);
  width: 327px;
  height: 225px;
  border-radius: 12px;
  padding: 25px 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.entry_request_denied_popup_title {
  text-align: center;
  font-family: var(--gibson-semibold);
  font-size: var(--font-24);
  line-height: 29px;
  color: var(--white);
  margin-top: 20px;
}

/* Resume class */
.resume_class_popup_container {
  width: 327px;
  height: 247px;
  display: flex;
  flex-direction: column;
  padding: 24px;
  position: relative;
}

.resume_class_popup_title {
  margin-bottom: 21px;
}

.resume_class_popup_button {
  position: absolute;
  bottom: 24px;
}

.resume_class_popup_button_accept {
  right: 24px;
}

.resume_class_popup_button_reject {
  right: 121px;
}

/* Screen share request */
.screen_share_request_popup_container {
  width: 327px;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 24px;
  position: relative;
}

.screen_share_request_popup_title {
  margin-bottom: 21px;
}

.screen_share_request_popup_button_accept {
  margin-left: 24px;
}

/* Sent out of class */
.sent_out_of_class_popup_container {
  width: 327px;
  height: 251px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sent_out_of_class_popup_icon {
  margin-top: 36px;
}

.sent_out_of_class_popup_title {
  margin-top: 20px;
}

.sent_out_of_class_popup_text {
  margin-top: 8px;
  text-align: center;
}

/* Audio request */
.audio_request_popup_container {
  width: 340px;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 24px;
  position: relative;
}

.audio_request_popup_title {
  margin-bottom: 10px;
}

.audio_request_popup_button {
  width: 80px;
  color: white;
  text-transform: uppercase;
  padding: 15px;
  border-radius: 10px;
  background-color: var(--gray);
  border: none;
  font-family: var(--gibson-semibold);
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.audio_request_popup_button_accept {
  margin-left: 24px;
}

.audio_request_popup_sound_input_bar {
  margin-top: 10px;
}

.student_live_class_popup_bottom_button_container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 16px;
}

.student_audio_request_popup_user_avatar {
  display: flex;
  align-items: center;
  color: var(--white);
  margin-bottom: 1em;
}

.student_audio_request_popup_user_avatar_details {
  line-height: 16px;
  font-size: var(--font-14);
  margin-left: 1em;
}

.student_audio_request_popup_user_avatar_details div {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: normal;
  word-wrap: break-word;
}
