.selected__user__chip__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 26px;
  border-radius: var(--border-radius-12);
  /* max-width: 190px; */
  width: 150px;
  margin: 3px 5px;
}

.selected__user__chip__container.selected__correct {
  border: 1px solid var(--gray);
}

.selected__user__chip__container.selected__incorrect {
  background-color: var(--red);
}

.selected__user__name {
  font-size: var(--font-15);
  font-family: var(--gibson-semibold);
  white-space: nowrap;
  width: 115px;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 10px;
  margin-right: 5px;
}

.selected__user__chip__container.selected__correct .selected__user__name {
  color: var(--gray);
}

.selected__user__chip__container.selected__incorrect .selected__user__name {
  color: var(--white);
}

.delete__icon__container {
  width: 21px;
  height: 21px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 3px;
}

.selected__user__chip__container.selected__correct .delete__icon__container {
  background-color: var(--gray);
}

.selected__user__chip__container.selected__incorrect .delete__icon__container {
  background-color: var(--white);
}

.selected__user__chip__container.selected__incorrect
  .delete__icon__container
  svg
  path {
  fill: var(--light-blue);
}
