.End_Session_Confirmation_Popup_Container {
  background-color: var(--dark-blue);
  width: 330px;
  border-radius: 10px;
  padding: 20px;
  height: 179px;
}

.feedback__popup__contaier {
  background-color: var(--dark-blue);
  width: 330px;
  border-radius: 10px;
  padding: 20px;
  min-height: 216px;
  margin: auto;
}

.End_Session_Confirmation_Popup_Heading p {
  color: white;
  font-size: 24px;
  font-family: var(--gibson-semibold);
}

.End_Session_Confirmation_Popup_Body p {
  color: white;
  font-size: 14px;
  font-family: var(--poppins-regular);
  opacity: 0.68;
  margin: 15px 0;
  line-height: 1.5em;
}
.End_Session_Confirmation_Popup_Footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  /* margin-top: 40px; */
}
.End_Session_Confirmation_Orange_Btn {
  background-image: linear-gradient(
    var(--light-orange-gradient-start),
    var(--light-orange-gradient-stop)
  );
  width: 80px;
  height: 44px;
  border: none;
  border-radius: 12px;
  color: white;
  font-family: var(--gibson-semibold);
  font-size: 13px;
}

.End_Session_Confirmation_Green_Btn {
  background-color: var(--green);
  width: 80px;
  height: 44px;
  border: none;
  border-radius: 12px;
  color: white;
  font-family: var(--gibson-semibold);
  font-size: 13px;
  margin-right: 10px;
}
