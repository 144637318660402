.details__popup__container {
  width: 327px;
  /*   height: 269px; */
  display: flex;
  flex-direction: column;
  overflow: hidden;
  line-height: normal;
  background: #2a2e43 0% 0% no-repeat padding-box;
  margin: auto;
  padding: 16px !important;
  color: white;
  border-radius: 20px;
}

.popup__header_details {
  font-family: var(--gibson-semibold);
  font-size: var(--font-24);
  color: var(--white);
  height: 34px;
  display: flex;
  justify-content: flex-end;
}

.details__popup__close__icon {
  text-align: right;
  cursor: pointer;
  position: absolute;
}

.details__popup__popup__line {
  /* margin-top: 4px; */
  margin-bottom: 9px;
  border-top: 1px solid var(--gray);
}

.details__popup__file__name {
  font-family: var(--poppins);
  font-size: var(--font-16);
  margin: 0px 0 9px 0px;
  height: 26px;
  width: 295px;
  overflow: overlay;
}

.details__popup__content {
  color: var(--white);
  margin-bottom: 5px;
  display: flex;
  flex-direction: column;
  min-height: 85px;
  width: 100%;
}

.details__popup__location {
  overflow: auto;
  display: flex;
  flex-flow: wrap;
}

.location_ellipsis_after_two_lines {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.details__popup__row {
  width: 295px;
  height: auto;
  margin: 2px 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.details__popup__content__LHS {
  font-family: var(--poppins-light);
  font-size: var(--font-12);
}
.details__popup__content__RHS {
  max-width: 65%;
  font-family: var(--poppins-light);
  font-size: var(--font-14);
  color: var(--gray);
  text-align: start;
  word-break: break-all;
}
