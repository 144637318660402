.Resources_Shared_File_Container {
  width: 45%;
  height: 100px;
  margin: 10px 10px 0 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-bottom: 1px solid rgba(69, 79, 99, 0.05);
  position: relative;
}

.Resources_Shared_File_Logo_Container {
  padding-right: 10px;
  position: relative;
}

.Resources_Shared_File_Logo_Shared {
  background-color: var(--green);
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: 0;
}

.Resources_Shared_File_Name_Time {
  width: 100%;
  display: flex;
}

.Resources_Shared_File_Name {
  font-family: var(--gibson-semibold);
  font-size: 16px;
  color: var(--light-blue);
  width: 70%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Resources_Shared_File_Details {
  width: 70%;
  margin-left: 10px;
  margin-right: 10px;
}

.Resources_Shared_File_Time {
  font-family: var(--gibson);
  font-size: 14px;
  color: var(--gray);
}

.Resources_Shared_File_Type {
  font-family: var(--gibson);
  font-size: 14px;
  color: var(--gray);
  margin-top: 5px;
}

.file__system__card__rename__input__box {
  margin-top: 4px;
  height: 26px;
  max-height: 26px;
  width: 141px;
  max-width: 141px;
  overflow: ellipsis;
  border-radius: 6px;
  border: solid 1px;
  border-color: var(--dark-grey);
}

.Uploaded_File_Card_Container {
  width: 85px;
  height: 90px;
  border-radius: 12px;
  background: #ffffff;
  box-shadow: 0 12px 16px rgba(69, 91, 99, 0.1);
  margin: 7px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.Uploaded_File_Card_Icon {
  width: 42px;
  height: 42px;
}

.Uploaded_File_Card_Text_Container {
  flex: 1;
  display: flex;
  align-items: center;
}

.Uploaded_File_Card_Text {
  font-family: var(--poppins-light) !important;
  font-size: 10px !important;
  color: #2a2e43 !important;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: center;
}

.Uploaded_File_Card_Cross_Button {
  position: absolute;
  top: 4px;
  right: 4px;
  cursor: pointer;
}

/* Uploading file element specific styles */

.File_Element_Container {
  display: flex;
  align-items: center;
  margin-bottom: 36px;
}

.File_Element_Icon_Image {
  width: 61px;
  height: 61px;
}

.File_Element_Name_And_Progress_Container {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  flex: 1;
  margin-left: 12px;
}

.File_Element_Name_And_Progress_Container progress {
  background: none;
  margin-top: 8px;
  height: 6px;
  border-radius: 3px;
  width: 100%;
}

.File_Element_Name_And_Progress_Container progress::-webkit-progress-value {
  background: #7986cb;
  border-radius: 3px;
}

.File_Element_Name_And_Progress_Container progress::-webkit-progress-bar {
  background: #daf0fd;
  border-radius: 3px;
}

.File_Element_Name_And_Progress_Container p {
  font-family: var(--poppins-light) !important;
  font-size: 12px !important;
  color: #d8d9db !important;
}

.File_Element_Right_Button {
  margin-left: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.File_Element_Right_Button_Cross {
  cursor: pointer;
}

/* Attachment File Related Styles */

.Attachment_File_Container {
  width: 64px;
  height: 96px;
  position: relative;
  margin-left: 10px;
  flex: none;
  display: flex;
  flex-direction: column;
  padding-top: 8px;
  justify-content: space-between;
}

.Attachment_File_Cancel_Button {
  position: absolute;
  top: 8px;
  right: 8px;
  background-color: var(--red);
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
}

.Attachment_File_Text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-bottom: 4px;
}

.tree__content__header {
  height: 32px;
  width: 266px;
  border-radius: var(--border-radius-12);
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: var(--gibson-semibold);
  font-size: var(--font-12);
  color: var(--dark-blue);
}

.tree__content__header__container {
  display: flex;
  border-radius: var(--border-radius-12);
  box-shadow: var(--cards-shadow-light);
}

.box__shadow__container {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: var(--border-radius-12);
  margin-top: 16px;
}

.box__shadow__container::-webkit-scrollbar-thumb {
  background-color: var(--gray);
  border-radius: 6px;
}

.box__shadow__container::-webkit-scrollbar-thumb:hover {
  background-color: var(--gray);
}

.box__shadow__container::-webkit-scrollbar-track {
  background-color: inherit;
  border-radius: 12px;
}

.active__tab {
  background-color: var(--dark-orange-gradient-stop);
  border-radius: var(--border-radius-12);
  text-align: center;
  color: var(--white);
  width: 50%;
  height: 100%;
  padding: 8px;
  text-transform: uppercase;
}

.non__active__tab {
  text-align: center;
  padding: 8px;
  width: 50%;
  height: 100%;
  text-transform: uppercase;
}

.tree__content__container {
  height: 100%;
  width: 100%;
  padding: 8px;
  border-radius: 21px;
  background-color: var(--white);
  box-shadow: var(--cards-shadow);
  display: flex;
  flex-direction: column;
}

.classcard__border {
  border: 3px solid var(--white);
  border-radius: var(--border-radius-7);
  box-shadow: var(--tabview-shadow);
}

.grayBackroung {
  background-color: var(--blue-gradient-start);
  opacity: 0.5;
}