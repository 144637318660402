/* Video Request */

.Video_Request_Popup_Modal_Background {
  position: fixed;
  z-index: 91;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Video_Request_Popup_Modal_Content {
  background-color: var(--dark-blue);
  padding: 19px 23px 19px 20px;
  width: 325px;
  height: auto;
  color: white;
  border-radius: 12px;
  box-shadow: var(--content-header-shadow);
}

.Video_Request_Popup_Title {
  font-family: var(--gibson-semibold);
  font-size: 24px;
  border-bottom: 1px solid #707070;
  padding-bottom: 13px;
  line-height: 29px;
}

.Video_Request_Popup_Body {
  padding-top: 20px;
  padding-bottom: 10px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.Video_Request_Popup_Text {
  font-family: var(--poppins);
  font-size: 14px;
  line-height: 22px;
  /* white-space: pre; */
  color: rgba(255, 255, 255, 0.68);
}

.Video_Request_Popup_Footer_Buttons {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.Video_Request_Popup_Footer_Buttons .button {
  width: 100%;
  color: white;
  text-transform: uppercase;
  padding: 15px;
  border-radius: 10px;
  background-color: var(--gray);
  border: none;
  font-family: var(--gibson-semibold);
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-top: 20px;
}

.Video_Request_Popup_Preview_Outer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 20px;
}