.share__file__folder__popup__container {
  width: 535px;
  padding: 16px;
  border-radius: 20px;
  background: #2a2e43 0% 0% no-repeat padding-box;
  margin: auto;
  color: white;
}

.share__file__folder__popup__header {
  display: flex;
  justify-content: space-between;
  position: relative;
  font-family: var(--gibson-semibold);
  font-size: var(--font-24);
  border-bottom: 1px solid var(--gray);
  padding-bottom: 8px;
}

.share__file__folder__popup__close__icon {
  right: 0;
  cursor: pointer;
  position: absolute;
}

.shared__with__container {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
}

.shared__with__container p {
  font-size: var(--font-16);
  font-family: var(--gibson-semibold);
  text-transform: uppercase;
  margin-top: 4px;
}

.shared__with__container button {
  background-color: transparent;
  border: 1px solid var(--red);
  font-size: var(--font-12);
  font-family: var(--gibson-semibold);
  border-radius: var(--border-radius-20);
  width: 117px;
  padding: 2px;
  color: var(--dark-text-color);
}

.shared__user__container {
  width: 100%;
  height: 169px;
  background-color: var(--transparent-black);
  border-radius: var(--border-radius-12);
  margin-top: 16px;
  position: relative;
  overflow: scroll;
}

.shared__popup__button__container {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.shared__popup__button__container button {
  border-radius: var(--border-radius-12);
  border: 1px solid var(--gray);
  font-family: var(--gibson-semibold);
  font-size: var(--font-13);
  width: 80px;
  height: 44px;
}

.cancel__button {
  color: var(--white);
  background-color: var(--gray);
  margin-right: 8px;
}

.done__button {
  color: var(--white);
  background-color: var(--green);
}

.shared__user__container .fixed__text__container {
  display: flex;
  justify-content: space-between;
  padding: 16px;
}

.shared__user__container .fixed__text__container .normal__text {
  display: flex;
}

.shared__user__container .text__container {
  display: block;
  margin-left: 10px;
}

.shared__user__container .text__container p {
  color: var(--white) !important;
  font-size: var(--font-14);
  font-family: var(--gibson-light);
  line-height: 20px;
}

.contact__info__shared__student {
  display: inline-flex;
  width: 100%;
  position: relative;
  align-items: center;
}

.student__shared__personal {
  font-family: var(--poppins-regular);
  font-size: var(--font-14);
  color: var(--white);
  width: -webkit-fill-available !important;
  margin-top: 4px;
  position: relative;
}

.student__shared__name {
  text-align: left;
}

.student__shared__detail__contact {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 20px;
}

.toggle__button {
  display: flex;
  justify-content: flex-end;
}

.shared__user__container .switch input {
  position: absolute;
  opacity: 0;
}

.shared__user__container .switch {
  display: inline-block;
  font-size: var(--font-20);
  height: 6px;
  width: 26px;
  background: #bdb9a6;
  border-radius: 1em;
  cursor: pointer;
}

.shared__user__container .switch div {
  height: 17px;
  width: 17px;
  border-radius: 1em;
  background: var(--red) !important;
  box-shadow: 0 0.1em 0.3em rgb(0 0 0 / 30%);
  transition: all 300ms;
  border: 3px solid var(--white);
  position: relative;
  top: -6px;
  left: -6px;
}

.shared__user__container .switch input:checked+div {
  -webkit-transform: translate3d(100%, 0, 0);
  -moz-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
  background: var(--green) !important;
}