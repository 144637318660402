.ellipsis__text__wrapper {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  /* word-break: break-all; */
  background-color: transparent;
  border: none;
  outline: none;
  resize: none !important;
}

.ellipsis__text__wrapper::after {
  content: attr();
}

.ellipsis__text__wrapper.single__line__ellipsis {
  white-space: nowrap;
}