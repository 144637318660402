@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css);
body {
  margin: 0;
  font-family: GibsonRegular;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

.IframeRenderer_linkclassroom-miro__VFc_C {
  border: 0px;
  outline: 0px;
  background: transparent;
}

.IframeRenderer_fullWidthFrame__1WGF3 {
  width: 100%;
  height: 100%;
}

.IframeRenderer_scaledFrame__BIkB3 {
  width: calc(100% * (1 / 0.3));
  height: calc(100% * (1 / 0.3));
  -webkit-transform: scale(0.3);
          transform: scale(0.3);
}

.IframeRenderer_frameAbsolute__1z2-M {
  position: absolute;
}

.IframeRenderer_errorMessage__sZE_g {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  align-items: center;
  background-color: var(--white);
  font-size: 1.2em;
}

.IframeRenderer_frameNoScroll__1a4yb {
  overflow: hidden !important;
}

.IframeRenderer_frameNoScroll__1a4yb ::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6,
label,
p {
  margin: 0;
  padding: 0;
  line-height: 1;
}

:root {
  --header-light-gray: #808080;
  --white: #ffffff;
  --light-white: #f4f4f4;
  --blue: #0000ff;
  --matt-blue: #5e5b74;
  --blue-gradient-start: #3c3c58;
  --blue-gradient-stop: #1c1c36;
  --dark-blue: #2a2e43;
  --darker-blue: #101b4f;
  --light-blue: #454f63;
  --cal-text: #52575d;
  --cal-unactive-text: #d0dbe5;
  --light-gray1: #e6e6e6;
  --gray3: #b8b8b8;
  --gray2: #99a1a7;
  --grey1: #808080;
  --gray: #78849e;
  --gray-dark: #383d4a;
  --purple: #c840e9;
  --pink: #ff5e99;
  --red: #ff4f67;
  --red-5: rgba(255, 79, 103, 0.1);
  --gray-border: #979797;
  --light-red: #e6e5e5;
  --light-red-border: #eeeeee;
  --matte-orange: #ff9057;
  --yellow: #ffb900;
  --yellow-5: rgba(255, 185, 0, 0.1);
  --light-orange-gradient-start: #fdbb5c;
  --light-orange-gradient-stop: #ffa015;
  --green: #17c8af;
  --dark-green: #00b59c;
  --lime-green: #59c260;
  --sky-blue: #3acce1;
  --terminal-blue: #5773ff;
  --forest-green: #008a76;
  --darker-green: #008a76;
  --orange: #fb963c;
  --orange-3: rgba(252, 115, 24, 0.2);
  --orange-2: #fc7318;
  --light-orange: #f8b757;
  --view-all-blue-button: #6672fb;
  --border-box-shadow-color: #d1cccc;
  --dark-orange-gradient-start: #fb9e58;
  --dark-orange-gradient-stop: #ff913e;
  --background-gray: rgba(235, 232, 232, 0.5);
  --light-gray: #f7f8fa;
  /* used in searchbar and activity icon background color */
  --seperator-color: #bbc5d5;
  /* used in searchbar seperator background */
  --searchbar-text-color: #354052;
  --light-transparent-bg-grey: rgba(120, 132, 158, 0.15);
  --dark-text-color: #959dad;
  --custom-tabview-tab-seperator: #f4f4f6;
  --people-attendance-average-table-legend: #e7ecf5;
  --light-border-color: #f5f5f5;
  --light-border-color2: #f7f7f7;
  --light-border-color-with-opacity: rgba(245, 245, 245, 0.13);
  --table-header-bg-color: rgba(227, 233, 239);
  --table-hover-bg-color: rgba(234, 242, 255);
  --pagination-caret-light-color: #e3e9ef;
  --details-table-seperator-color: #ebf2f7;
  --calendar-header-day-name-color: #b3b8bd;
  --calendar-header-date-color: #52575d;
  --calendar-toolbar-prev-next-color: #333333;
  --add-class-card-bg-color: #c4cad7;
  --copy-link-container-bg-color: rgba(60, 60, 88, 0.5);
  --light-gray-border-color: #707070;
  --light-gray-border-color-opacity: rgba(112, 112, 112, 0.4);
  --reminder-popup-dispription-bg-color: rgb(211, 211, 211);
  --submission-card-bg-color: #e1e1e1;
  --whiteboard-border-color: #e0dede;
  --weekly-calendar-today-column-background-color: rgba(0, 181, 156, 0.2);
  --light-gray-text-color: rgba(120, 132, 158, 0.56);
  --month-calendar-popup-day-detail-box-border-color: rgba(133, 146, 173, 0.17);
  --grid-icon-inactive-color: #b5bdcf;
  --transparent-white: rgba(255, 255, 255, 0.9);
  --transparent-black: rgba(0, 0, 0, 0.3);
  --transparent-black-2: rgba(0, 0, 0, 0.5);
  --black: #000000;
  --black-1: #1D1D2F;
  --start-class-video-button-color: #353a50;
  --settings-tab-default-button-border-color: #bebebe;
  --login-page-background-color: #f5f5f7;
  --landing-page-section-header-color: #1d1d1d;
  --dark-grey: #a5adbb;
  --light-grey: #b1acac;
  --light-gray-2: #ebe8e8;
  --light-gray-3: #d8d9db;
  --light-gray-4: #e8eaef;
  --light-gray-5: #c8c9cb;
  --light-gray-6: #cccccc;
  --light-dark-gray: linear-gradient(#92a1c4, #4a5365);
  --transparent-blue: rgba(69, 79, 99, 0.1);
  --translucent-gray: rgba(120, 132, 158, 0.5);
  --transparent-blue-text: rgba(39, 39, 65, 0.56);

  --header-gradient: linear-gradient(to top,
      rgba(60, 60, 88, 0.6),
      rgba(28, 28, 54, 0.6));
  --light-blue: rgb(69, 79, 99);
  --blue-time-indicator: #3497fd;
  --input-placeholder: rgba(120, 132, 158, 0.56);
  --blue-button: #508ff4;
  --icon-button-default-bg: rgba(42, 46, 67, 0.3);
  --icon-button-hover-bg: rgba(42, 46, 67, 0.5);
  --quiz-blue-gradient: linear-gradient(90deg, #68dae0 0%, #2071ff 100%) 0% 0% no-repeat;
  --gray-radial-shadow: radial-gradient(50% 50% at 50% 50%,
      #ffffff 0%,
      #fdfdfd 59.4%,
      #f6f6f7 80.8%,
      #eaebec 96%,
      #e6e7e8 100%);
  --pink: #ff5e99;

  /* font sizes */
  --font-8: 8px;
  --font-9: 9px;
  --font-10: 10px;
  --font-11: 11px;
  --font-12: 12px;
  --font-13: 13px;
  --font-14: 14px;
  --font-15: 15px;
  --font-16: 16px;
  --font-20: 20px;
  --font-21: 21px;
  --font-22: 22px;
  --font-18: 18px;
  --font-23: 22px;
  --font-23: 23px;
  --font-24: 24px;
  --font-25: 25px;
  --font-26: 26px;
  --font-28: 28px;
  --font-29: 29px;
  --font-30: 30px;
  --font-31: 31px;
  --font-32: 32px;
  --font-33: 33px;
  --font-34: 34px;
  --font-35: 35px;
  --font-36: 36px;
  --font-40: 40px;
  --font-42: 42px;
  --font-49: 49px;

  /* font-family */
  /* when uncommenting gibson light and gibson bold, do uncomment the imports in index.html as well  */
  /* --gibson-light: "GibsonLight"; */
  /* --gibson-bold: "GibsonBold"; */

  --gibson: "GibsonRegular";
  --gibson-light: "GibsonLight";
  --gibson-semibold: "GibsonSemiBold";
  --poppins: "PoppinsRegular";
  --poppins-light: "PoppinsLight";
  --poppins-medium: "PoppinsMedium";
  --poppins-semibold: "PoppinsSemiBold";
  --poppins-bold: "PoppinsBold";
  --poppins-extrabold: "PoppinsExtraBold";
  --avenir-light: "AvenirLight";

  /* border-radius */
  --border-radius-3: 3px;
  --border-radius-4: 4px;
  --border-radius-5: 5px;
  --border-radius-6: 6px;
  --border-radius-8: 8px;
  --border-radius-9: 9px;
  --border-radius-10: 10px;
  --border-radius-12: 12px;
  --border-radius-13: 13px;
  --border-radius-15: 15px;
  --border-radius-16: 16px;
  --border-radius-19: 19px;
  --border-radius-18: 18px;
  --border-radius-21: 21px;
  --border-radius-22: 22px;
  --border-radius-27: 27px;
  --border-radius-20: 20px;
  --border-radius-26: 26px;
  --border-radius-30: 30px;
  --border-radius-32: 32px;
  --border-radius-35: 35px;
  --border-radius-40: 40px;
  --border-radius-50: 50px;
  --border-radius-round: 50%;

  /* box-shadow */
  --schedule-lecture-reminder-box-shadow: 0px 4px 16px #455b6314;
  --assignment-header-box-shadow: 0px 12px 16px #455b6314;
  --tabview-shadow: 0px 0px 16px rgba(0, 0, 0, 0.16) !important;
  --cards-shadow: 0px 0px 16px rgba(0, 0, 0, 0.16) !important;
  --border-box-shadow: inset 0px 0px 10px #d1cccc !important;
  --create-tab-icon-shadow: 0px 12px 16px rgba(69, 91, 99, 0.1) !important;
  --cards-shadow-light: 0px 4px 16px rgba(69, 91, 99, 0.08) !important;
  --live-class-wide-tab-shadow: 0px 3px 42px rgba(0, 0, 0, 0.16) !important;
  --live-class-narrow-tab-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16) !important;
  --batches-activity-shadow: 0px 8px 16px rgba(0, 0, 0, 0.16) !important;
  --navigation-bar-shadow: 0px 20px 30px rgba(0, 0, 0, 0.06) !important;
  --calendar-popup-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08) !important;
  --live-class-timer-shadow: 0px 12px 16px rgba(120, 132, 158, 0.13) !important;
  --student-live-class-notebook-shadow: 0px 3px 32px rgba(0, 0, 0, 0.16) !important;
  --login-btn-box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.16) !important;
  --logininput-box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.16) !important;
  --mobile-login-btn-box-shadow: 0px 5px 16px rgba(0, 0, 0, 0.16) !important;
  --icon-bg-box-shadow: 0px 0px 45px rgba(0, 0, 0, 0.16) !important;
  --mybag-resources-card-shadow: 0px 7px 20px rgba(0, 0, 0, 0.16) !important;
  --edit-lecture-popup-box-shadow: 0px 10px 40px rgb(2 2 2 / 11%) !important;
  --class-description-input-container-box-shadow: 0px 5px 11px rgb(69 91 99 / 8%) !important;
  --class-modal-content2-box-shadow: 0px 10px 40px rgba(2, 2, 2, 0.11) !important;
  --edit-lecture-popup-box-shadow: 0px 10px 40px rgb(2 2 2 / 11%) !important;
  --class-signin-inputs-error-box-shadow: 0px 2px 11px var(--red) !important;
  --class-signin-inputs-box-shadow: 0px 5px 11px rgba(69, 91, 99, 0.08) !important;
  --class-signin-inputs2-box-shadow: 0px 5px 11px rgba(69, 91, 99, 0.08) !important;
  --class-signin-inputs3-box-shadow: 0px 5px 11px rgba(69, 91, 99, 0.08) !important;
  --input-and-teacher-count-containe-error-box-shadow: 0px 2px 11px var(--red) !important;
  --pending-request-count-box-shoadow: 0 2px 4px #78849e;
  --classroom-tile-container-shadow: 0px 3px 42px rgba(0, 0, 0, 0.16) !important;
  --big-link-logo-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06) !important;
  --content-header-shadow: 0px 10px 18px #00000029 !important;
  --thick-button-shadow: 0px 4px 8px var(--gray) !important;
  --resource-tile-background-shadow-box: 0 16px 24px rgb(29 84 180 / 12%) !important;
  --live-quiz-tab-header-shadow: 0px 15px 10px rgba(13, 23, 35, 0.12) !important;
  --live-quiz-tab-content-shadow: 0px 4px 16px rgba(69, 91, 99, 0.1) !important;
  --table-action-menu-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25) !important;

  --calendar-active-date-shadow: 0px 5px 25px rgba(82, 87, 93, 0.1) !important;
  --week-selectore-calender-shadow: 0px 4px 18px rgba(0, 0, 0, 0.16) !important;
  --overview-card-shadow: 0px 8px 16px rgba(0, 0, 0, 0.16) !important;
  /* others */
  --chat-tablist-height: 43px;
  --right-column-width: 294px;
  --max-width-landing-page: 1800px;
  --custom-tabview-tab-width: 160px;
  /* do not use this value anywhere to define the width, as this value will be modfied in run time by JS */
  --resizable-layout-right-column-width: 294px;
  --link-navigation-bar-height: 70px;
  --navigation-sidebar-width: 70px;
  --navigation-layout-horizontal-padding: 24px;
  --live-class-control-panel-height: 128px;
  --live-class-lhs-tab-header-height: 43px;
  --navigation-layout-top-padding: 25px;
  --screen-min-height: 600px;
  --screen-min-width: 1000px;

  /* zindex in the descending order of priority */
  --zindex-alert: 10040;
  --zindex-loader: 10000;
  --zindex-popup-content: 1060;
  --zindex-popup-backdrop: 1040;
}

html {
  height: 100%;
  font-size: 16px;
}

body {
  width: 100%;
  min-height: 100vh;
  background-image: linear-gradient(#ffffff, #ebe8e8);
  background-image: linear-gradient(var(--white), var(--light-gray-2));
  background-attachment: fixed;
}

#root {
  height: 100%;
  width: 100%;
  position: absolute;
}

button:focus {
  outline: 0;
}

input:focus {
  outline: 0;
}

textarea:focus {
  outline: 0;
}

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  /* Old versions of Firefox */
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.hide__scrollbar {
  scrollbar-width: none;
  /* mozilla */
}

.hide__scrollbar::-webkit-scrollbar {
  /* chrome, edge */
  display: none;
  -ms-overflow-style: none;
}

.dark__gradient {
  background-image: linear-gradient(#3c3c58,
      #1c1c36);
  background-image: linear-gradient(var(--blue-gradient-start),
      var(--blue-gradient-stop));
}

.bg__white {
  background-color: #ffffff;
  background-color: var(--white);
}

.blur_background_screen {
  filter: blur(8px);
  -webkit-filter: blur(8px);
}

.current__scrollbar {
  position: fixed;
  width: 100%;
}

/* random iframe is being added on top of the page and blocks the click events*/
body>iframe,
.os-content>iframe {
  pointer-events: none;
}

/* styles for preview library - on no render */
#no-renderer-download {
  position: absolute;
  bottom: 1em;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  background-color: #508ff4;
  background-color: var(--blue-button);
  color: white;
  text-transform: uppercase;
  font-family: "GibsonSemiBold";
  font-family: var(--gibson-semibold);
  white-space: nowrap;
  padding: 1.2em 2em;
  width: auto;
  border-radius: 12px;
  border-radius: var(--border-radius-12);
  text-decoration: none;
}

/* Animations */

.animated {
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes wobble {
  0% {
    -webkit-transform: translateX(0%);
  }

  15% {
    -webkit-transform: translateX(-25%) rotate(-5deg);
  }

  30% {
    -webkit-transform: translateX(20%) rotate(3deg);
  }

  45% {
    -webkit-transform: translateX(-15%) rotate(-3deg);
  }

  60% {
    -webkit-transform: translateX(10%) rotate(2deg);
  }

  75% {
    -webkit-transform: translateX(-5%) rotate(-1deg);
  }

  100% {
    -webkit-transform: translateX(0%);
  }
}

@keyframes wobble {
  0% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }

  15% {
    -webkit-transform: translateX(-25%) rotate(-5deg);
            transform: translateX(-25%) rotate(-5deg);
  }

  30% {
    -webkit-transform: translateX(20%) rotate(3deg);
            transform: translateX(20%) rotate(3deg);
  }

  45% {
    -webkit-transform: translateX(-15%) rotate(-3deg);
            transform: translateX(-15%) rotate(-3deg);
  }

  60% {
    -webkit-transform: translateX(10%) rotate(2deg);
            transform: translateX(10%) rotate(2deg);
  }

  75% {
    -webkit-transform: translateX(-5%) rotate(-1deg);
            transform: translateX(-5%) rotate(-1deg);
  }

  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
}

.wobble {
  -webkit-animation-name: wobble;
  animation-name: wobble;
}
.End_Session_Confirmation_Popup_Heading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
}

.End_Session_Confirmation_Popup_Heading img {
    margin: 10px;
}

.End_Session_Confirmation_Popup_Content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Rate_Session_Confirmation_Popup_Stars {
    display: flex;
}

.End_Session_Confirmation_Popup_Container {
  background-color: var(--dark-blue);
  width: 330px;
  border-radius: 10px;
  padding: 20px;
  height: 179px;
}

.feedback__popup__contaier {
  background-color: var(--dark-blue);
  width: 330px;
  border-radius: 10px;
  padding: 20px;
  min-height: 216px;
  margin: auto;
}

.End_Session_Confirmation_Popup_Heading p {
  color: white;
  font-size: 24px;
  font-family: var(--gibson-semibold);
}

.End_Session_Confirmation_Popup_Body p {
  color: white;
  font-size: 14px;
  font-family: var(--poppins-regular);
  opacity: 0.68;
  margin: 15px 0;
  line-height: 1.5em;
}
.End_Session_Confirmation_Popup_Footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  /* margin-top: 40px; */
}
.End_Session_Confirmation_Orange_Btn {
  background-image: linear-gradient(
    var(--light-orange-gradient-start),
    var(--light-orange-gradient-stop)
  );
  width: 80px;
  height: 44px;
  border: none;
  border-radius: 12px;
  color: white;
  font-family: var(--gibson-semibold);
  font-size: 13px;
}

.End_Session_Confirmation_Green_Btn {
  background-color: var(--green);
  width: 80px;
  height: 44px;
  border: none;
  border-radius: 12px;
  color: white;
  font-family: var(--gibson-semibold);
  font-size: 13px;
  margin-right: 10px;
}

/* General styles */

.student_live_class_popup_background {
  position: fixed;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  z-index: 91;
  top: 0;
  left: 0;
}

.student_live_class_popup_container {
  background-color: var(--dark-blue);
  border-radius: 12px;
  box-shadow: var(--content-header-shadow);
}

.student_live_class_popup_title_font {
  font-family: var(--gibson-semibold);
  font-size: var(--font-24);
  line-height: 29px;
  color: var(--white);
}

.student_live_class_popup_text_font {
  font-family: var(--poppins);
  font-size: var(--font-14);
  line-height: 22px;
  color: rgba(255, 255, 255, 0.68);
}

.student_live_class_popup_button {
  width: 80px;
  height: 44px;
  border-radius: 12px;
  background: #17c8af;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: var(--gibson-semibold);
  font-size: var(--font-13);
  line-height: 16px;
  color: var(--white);
  cursor: pointer;
}

.student_live_class_popup_button_accept {
  background: #17c8af;
}

.student_live_class_popup_button_reject {
  background-image: linear-gradient(#fdbb5c, #ffa015);
}

/* Popup specific styles */

.request_pending_popup_container {
  background-color: var(--dark-blue);
  width: 327px;
  height: 276px;
  border-radius: 12px;
  padding: 25px 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.request_pending_popup_icon {
  margin-bottom: 14px;
}

.request_pending_popup_title {
  text-align: center;
  font-family: var(--gibson-semibold);
  font-size: var(--font-24);
  line-height: 29px;
  color: var(--white);
  margin-bottom: 7px;
}

.request_pending_popup_text {
  text-align: center;
  font-family: var(--poppins);
  font-size: var(--font-14);
  line-height: 22px;
  color: rgba(255, 255, 255, 0.68);
  white-space: pre;
}

.request_pending_popup_button {
  margin-top: 16px;
}

.entry_request_denied_popup_container {
  background-color: var(--dark-blue);
  width: 327px;
  height: 225px;
  border-radius: 12px;
  padding: 25px 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.entry_request_denied_popup_title {
  text-align: center;
  font-family: var(--gibson-semibold);
  font-size: var(--font-24);
  line-height: 29px;
  color: var(--white);
  margin-top: 20px;
}

/* Resume class */
.resume_class_popup_container {
  width: 327px;
  height: 247px;
  display: flex;
  flex-direction: column;
  padding: 24px;
  position: relative;
}

.resume_class_popup_title {
  margin-bottom: 21px;
}

.resume_class_popup_button {
  position: absolute;
  bottom: 24px;
}

.resume_class_popup_button_accept {
  right: 24px;
}

.resume_class_popup_button_reject {
  right: 121px;
}

/* Screen share request */
.screen_share_request_popup_container {
  width: 327px;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 24px;
  position: relative;
}

.screen_share_request_popup_title {
  margin-bottom: 21px;
}

.screen_share_request_popup_button_accept {
  margin-left: 24px;
}

/* Sent out of class */
.sent_out_of_class_popup_container {
  width: 327px;
  height: 251px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sent_out_of_class_popup_icon {
  margin-top: 36px;
}

.sent_out_of_class_popup_title {
  margin-top: 20px;
}

.sent_out_of_class_popup_text {
  margin-top: 8px;
  text-align: center;
}

/* Audio request */
.audio_request_popup_container {
  width: 340px;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 24px;
  position: relative;
}

.audio_request_popup_title {
  margin-bottom: 10px;
}

.audio_request_popup_button {
  width: 80px;
  color: white;
  text-transform: uppercase;
  padding: 15px;
  border-radius: 10px;
  background-color: var(--gray);
  border: none;
  font-family: var(--gibson-semibold);
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.audio_request_popup_button_accept {
  margin-left: 24px;
}

.audio_request_popup_sound_input_bar {
  margin-top: 10px;
}

.student_live_class_popup_bottom_button_container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 16px;
}

.student_audio_request_popup_user_avatar {
  display: flex;
  align-items: center;
  color: var(--white);
  margin-bottom: 1em;
}

.student_audio_request_popup_user_avatar_details {
  line-height: 16px;
  font-size: var(--font-14);
  margin-left: 1em;
}

.student_audio_request_popup_user_avatar_details div {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: normal;
  word-wrap: break-word;
}

.parent-view-16x9 {
  width: 100%;
  padding-top: 56.25%;
  height: 0;
  position: relative;
}

.view-16x9 {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.parent-view {
  height: 0;
  width: 100%;
  position: relative;
}

.child-view {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.neutral-view {
  width: 100%;
  height: 100%;
}

.mobile-screen-view {
  height: 100%;
  position: absolute;
  top: 0;
  /* left: 38%; */
  width: 100%;
}

/* Video Request */

.Video_Request_Popup_Modal_Background {
  position: fixed;
  z-index: 91;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Video_Request_Popup_Modal_Content {
  background-color: var(--dark-blue);
  padding: 19px 23px 19px 20px;
  width: 325px;
  height: auto;
  color: white;
  border-radius: 12px;
  box-shadow: var(--content-header-shadow);
}

.Video_Request_Popup_Title {
  font-family: var(--gibson-semibold);
  font-size: 24px;
  border-bottom: 1px solid #707070;
  padding-bottom: 13px;
  line-height: 29px;
}

.Video_Request_Popup_Body {
  padding-top: 20px;
  padding-bottom: 10px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.Video_Request_Popup_Text {
  font-family: var(--poppins);
  font-size: 14px;
  line-height: 22px;
  /* white-space: pre; */
  color: rgba(255, 255, 255, 0.68);
}

.Video_Request_Popup_Footer_Buttons {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.Video_Request_Popup_Footer_Buttons .button {
  width: 100%;
  color: white;
  text-transform: uppercase;
  padding: 15px;
  border-radius: 10px;
  background-color: var(--gray);
  border: none;
  font-family: var(--gibson-semibold);
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-top: 20px;
}

.Video_Request_Popup_Preview_Outer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 20px;
}
.Start_Now_Popup_Mic_Bars_Container {
  display: flex;
  margin-top: 5px;
  align-items: flex-end;
}

.mic-icon-start-now {
  min-height: 33px;
  max-height: 33px;
  min-width: 33px;
  max-width: 33px;
  cursor: pointer;
}


.initialAvatar_container{
    width: 17px;
    height: 17px;
    border-radius: 8.5px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #78849E;
}

.child-view {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.parent-view {
  height: 0;
  width: 100%;
  position: relative;
}
.Student_Live_Class_Teacher_Dp_Screen_Container {
  width: 100%;
  height: 100%;
  border-radius: 15px;
  position: absolute;
  z-index: 6;
  top: 0;
  left: 0;
}

.Student_Live_Class_Teacher_Dp_Container_Content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Student_Live_Class_Teacher_Dp_Container_Content .initial__avatar__name {
  /* font-size: var(--teacher-dp-initial-font-size) !important; */
}

.parent-view-user-avatar {
  height: 0;
  position: relative;
}

.Student_Live_Class_Teacher_Dp_Container_Speaker_Name{
  color: white;
  max-width: 80%;
  margin-top: 3px;
  text-align: center;
  font-family: var(--gibson-semibold);
}
.ellipsis__text__wrapper {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  /* word-break: break-all; */
  background-color: transparent;
  border: none;
  outline: none;
  resize: none !important;
}

.ellipsis__text__wrapper::after {
  content: attr();
}

.ellipsis__text__wrapper.single__line__ellipsis {
  white-space: nowrap;
}
.details__popup__container {
  width: 327px;
  /*   height: 269px; */
  display: flex;
  flex-direction: column;
  overflow: hidden;
  line-height: normal;
  background: #2a2e43 0% 0% no-repeat padding-box;
  margin: auto;
  padding: 16px !important;
  color: white;
  border-radius: 20px;
}

.popup__header_details {
  font-family: var(--gibson-semibold);
  font-size: var(--font-24);
  color: var(--white);
  height: 34px;
  display: flex;
  justify-content: flex-end;
}

.details__popup__close__icon {
  text-align: right;
  cursor: pointer;
  position: absolute;
}

.details__popup__popup__line {
  /* margin-top: 4px; */
  margin-bottom: 9px;
  border-top: 1px solid var(--gray);
}

.details__popup__file__name {
  font-family: var(--poppins);
  font-size: var(--font-16);
  margin: 0px 0 9px 0px;
  height: 26px;
  width: 295px;
  overflow: overlay;
}

.details__popup__content {
  color: var(--white);
  margin-bottom: 5px;
  display: flex;
  flex-direction: column;
  min-height: 85px;
  width: 100%;
}

.details__popup__location {
  overflow: auto;
  display: flex;
  flex-flow: wrap;
}

.location_ellipsis_after_two_lines {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.details__popup__row {
  width: 295px;
  height: auto;
  margin: 2px 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.details__popup__content__LHS {
  font-family: var(--poppins-light);
  font-size: var(--font-12);
}
.details__popup__content__RHS {
  max-width: 65%;
  font-family: var(--poppins-light);
  font-size: var(--font-14);
  color: var(--gray);
  text-align: start;
  word-break: break-all;
}

.delete__file__container__wrapper__inner {
  width: 490px;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 16px;
  background: #2a2e43 0% 0% no-repeat padding-box;
  margin: auto;
  padding: 16px !important;
  color: white;
  border-radius: 20px;
}
.delete_popup_header {
  font-family: var(--gibson-semibold);
  font-size: var(--font-24);
  color: var(--white);
  display: flex;
  height: 34px;
  justify-content: flex-end;
}

.delete__file__popup__close__icon {
  text-align: right;
  cursor: pointer;
  display: flex;
  /* margin: -30px 0px 0 247px; */
  align-items: center;
  /* position: absolute; */
}

.delete__file__popup__row {
  /* width: 249px; */
  width: 100%;
  display: flex;
  position: relative;
  /* background-color: aquamarine; */
  height: 76px;
  flex-direction: column;
}

.delete__file__popup__row .delete__file__popup__name {
  font-family: var(--gibson-semibold);
  font-size: var(--font-16);
  color: var(--white);
}

.delete__file__popup__row .delete__file__popup__size {
  font-family: "PoppinsRegular";
  font-size: 14px;
  color: var(--white);
  opacity: 0.6;
}

.delete__file__popup__row .delete__file__popup__location {
  font-family: var(--poppins-light);
  font-size: 11px;
  color: var(--white);
  opacity: 100%;
}

.delete__file__popup__content__confirmation__container {
  /* background-color: black; */
  color: var(--white);
  display: flex;
  /* height: 108px; */
  /* width: 257px; */
  width: 100%;
  flex-direction: column;
}

.delete__file__popup__confirmation {
  font-family: var(--poppins-semibold);
  font-size: var(--font-14);
  line-height: 22px;
  margin-bottom: 8px;
}

.delete__file__popup__note {
  font-family: var(--poppins-light);
  font-size: var(--font-14);
  color: var(--gray);
  line-height: 22px;
}

.delete__file__popup__button__row__wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  position: relative;
}

.delete__file__popup__back__button {
  width: 80px;
  height: 44px;
  background-color: var(--green);
  border: none;
  border-radius: 12px;
  color: var(--white);
  font-family: var(--gibson-semibold);
  font-size: var(--font-13);
}

.delete__file__popup__delete__button {
  width: 80px;
  height: 44px;
  background-color: var(--red);
  border: none;
  border-radius: 12px;
  color: var(--white);
  font-family: var(--gibson-semibold);
  font-size: var(--font-13);
}

.delete__file__popup__header {
  font-family: var(--gibson-semibold);
  font-size: var(--font-24);
  color: var(--white);
  display: inline;
  margin-bottom: 10px;
  display: flex;
}

.move__and__copy__file__head {
  font-family: var(--gibson-semibold);
  font-size: var(--font-24);
  color: var(--white);
  height: 34px;
  display: flex;
  justify-content: flex-end;
}

.move__copy__file__container__wrapper__inner {
  width: 625px;
  padding: 16px;
  border-radius: 20px;
  background: #2a2e43 0% 0% no-repeat padding-box;
  margin: auto;
  color: white;
}

.move__and__copy__popup__close__icon {
  text-align: right;
  cursor: pointer;
  position: absolute;
}
.move__and__copy__popup__line {
  margin-top: 0px;
  margin-bottom: 10px;
  border-top: 1px solid var(--gray);
}

.move__and__copy__file__popup__row {
  width: 598px;
  display: flex;
  position: relative;
  /* background-color: aquamarine; */
  /* height: 40px; */
  flex-direction: column;
  margin: 0 0 10px 0;
}

.move__and__copy__file__popup__row .move__and__copy__file__popup__name {
  font-family: var(--gibson-semibold);
  font-size: var(--font-16);
  color: var(--white);
}

.move__and__copy__file__popup__content__wrapper {
  min-height: 289px;
  max-width: 593px;
}

.move__and__copy__file__content__head {
  font-family: var(--gibson-semibold);
  font-size: var(--font-16);
}

.move__and__copy__file__content__file__browser {
  min-height: 265px;
  min-width: 593px;
  height: 277px;
  width: 593px;
  background-color: var(--light-blue);
  border-radius: 20px;
  margin-top: 8px;
}

.move__and__copy__file__popup__button__row__wrapper {
  height: 44px;
  display: flex;
  justify-content: flex-end;
  position: relative;
  margin-top: 8px;
}
.move__and__copy__file__popup__back__button {
  width: 80px;
  height: 44px;
  background-color: var(--gray);
  border: none;
  border-radius: 12px;
  color: var(--white);
  font-family: var(--gibson-semibold);
  font-size: var(--font-13);
  margin-right: 12px;
}

.move__and__copy__file__popup__moveorcopy__button {
  width: 80px;
  height: 44px;
  background-color: var(--green);
  border: none;
  border-radius: 12px;
  color: var(--white);
  font-family: var(--gibson-semibold);
  font-size: var(--font-13);
}

.link__sliding__tab__header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 32px;
  position: relative;
}

.link__sliding__tab__header .link__sliding__tab__lhs {
  display: flex;
  align-items: center;
  height: 100%;
  flex: 1 1;
}

.link__sliding__tab .link__sliding__tab__name {
  font-family: var(--gibson-semibold);
  font-size: var(--font-12);
  color: var(--white);
}

.link__sliding__tab {
  width: 106px;
  height: 32px;
  border-radius: var(--border-radius-12);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 2;
  text-transform: uppercase;
}

.link__sliding__tab__active {
  background-color: var(--dark-orange-gradient-stop);
  border-radius: var(--border-radius-12);
  text-align: center;
  cursor: pointer;
}

.link__sliding__tab__inactive {
  border-radius: var(--border-radius-12);
  text-align: center;
  background-color: var(--copy-link-container-bg-color);
}

.Resources_Shared_File_Container {
  width: 45%;
  height: 100px;
  margin: 10px 10px 0 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-bottom: 1px solid rgba(69, 79, 99, 0.05);
  position: relative;
}

.Resources_Shared_File_Logo_Container {
  padding-right: 10px;
  position: relative;
}

.Resources_Shared_File_Logo_Shared {
  background-color: var(--green);
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: 0;
}

.Resources_Shared_File_Name_Time {
  width: 100%;
  display: flex;
}

.Resources_Shared_File_Name {
  font-family: var(--gibson-semibold);
  font-size: 16px;
  color: var(--light-blue);
  width: 70%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Resources_Shared_File_Details {
  width: 70%;
  margin-left: 10px;
  margin-right: 10px;
}

.Resources_Shared_File_Time {
  font-family: var(--gibson);
  font-size: 14px;
  color: var(--gray);
}

.Resources_Shared_File_Type {
  font-family: var(--gibson);
  font-size: 14px;
  color: var(--gray);
  margin-top: 5px;
}

.file__system__card__rename__input__box {
  margin-top: 4px;
  height: 26px;
  max-height: 26px;
  width: 141px;
  max-width: 141px;
  overflow: ellipsis;
  border-radius: 6px;
  border: solid 1px;
  border-color: var(--dark-grey);
}

.Uploaded_File_Card_Container {
  width: 85px;
  height: 90px;
  border-radius: 12px;
  background: #ffffff;
  box-shadow: 0 12px 16px rgba(69, 91, 99, 0.1);
  margin: 7px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.Uploaded_File_Card_Icon {
  width: 42px;
  height: 42px;
}

.Uploaded_File_Card_Text_Container {
  flex: 1 1;
  display: flex;
  align-items: center;
}

.Uploaded_File_Card_Text {
  font-family: var(--poppins-light) !important;
  font-size: 10px !important;
  color: #2a2e43 !important;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: center;
}

.Uploaded_File_Card_Cross_Button {
  position: absolute;
  top: 4px;
  right: 4px;
  cursor: pointer;
}

/* Uploading file element specific styles */

.File_Element_Container {
  display: flex;
  align-items: center;
  margin-bottom: 36px;
}

.File_Element_Icon_Image {
  width: 61px;
  height: 61px;
}

.File_Element_Name_And_Progress_Container {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  flex: 1 1;
  margin-left: 12px;
}

.File_Element_Name_And_Progress_Container progress {
  background: none;
  margin-top: 8px;
  height: 6px;
  border-radius: 3px;
  width: 100%;
}

.File_Element_Name_And_Progress_Container progress::-webkit-progress-value {
  background: #7986cb;
  border-radius: 3px;
}

.File_Element_Name_And_Progress_Container progress::-webkit-progress-bar {
  background: #daf0fd;
  border-radius: 3px;
}

.File_Element_Name_And_Progress_Container p {
  font-family: var(--poppins-light) !important;
  font-size: 12px !important;
  color: #d8d9db !important;
}

.File_Element_Right_Button {
  margin-left: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.File_Element_Right_Button_Cross {
  cursor: pointer;
}

/* Attachment File Related Styles */

.Attachment_File_Container {
  width: 64px;
  height: 96px;
  position: relative;
  margin-left: 10px;
  flex: none;
  display: flex;
  flex-direction: column;
  padding-top: 8px;
  justify-content: space-between;
}

.Attachment_File_Cancel_Button {
  position: absolute;
  top: 8px;
  right: 8px;
  background-color: var(--red);
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
}

.Attachment_File_Text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-bottom: 4px;
}

.tree__content__header {
  height: 32px;
  width: 266px;
  border-radius: var(--border-radius-12);
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: var(--gibson-semibold);
  font-size: var(--font-12);
  color: var(--dark-blue);
}

.tree__content__header__container {
  display: flex;
  border-radius: var(--border-radius-12);
  box-shadow: var(--cards-shadow-light);
}

.box__shadow__container {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: var(--border-radius-12);
  margin-top: 16px;
}

.box__shadow__container::-webkit-scrollbar-thumb {
  background-color: var(--gray);
  border-radius: 6px;
}

.box__shadow__container::-webkit-scrollbar-thumb:hover {
  background-color: var(--gray);
}

.box__shadow__container::-webkit-scrollbar-track {
  background-color: inherit;
  border-radius: 12px;
}

.active__tab {
  background-color: var(--dark-orange-gradient-stop);
  border-radius: var(--border-radius-12);
  text-align: center;
  color: var(--white);
  width: 50%;
  height: 100%;
  padding: 8px;
  text-transform: uppercase;
}

.non__active__tab {
  text-align: center;
  padding: 8px;
  width: 50%;
  height: 100%;
  text-transform: uppercase;
}

.tree__content__container {
  height: 100%;
  width: 100%;
  padding: 8px;
  border-radius: 21px;
  background-color: var(--white);
  box-shadow: var(--cards-shadow);
  display: flex;
  flex-direction: column;
}

.classcard__border {
  border: 3px solid var(--white);
  border-radius: var(--border-radius-7);
  box-shadow: var(--tabview-shadow);
}

.grayBackroung {
  background-color: var(--blue-gradient-start);
  opacity: 0.5;
}
.link__sliding__tab__wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.link__sliding__tab__wrapper .link__sliding__tab__header__wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  height: calc(32px + 14px);
  padding: 7px 0;
}

.link__sliding__tab__wrapper .link__sliding__tab__header__wrapper.with__separator {
  border-top: 1px solid var(--light-gray-border-color);
  border-bottom: 1px solid var(--light-gray-border-color-opacity);
}

.link__sliding__tab__wrapper .link__sliding__tab__header__wrapper.link__sliding__tab__without__bottom__separator {
  border-bottom: none;
}

.link__sliding__tab__wrapper.with__top__separator {
  border-top: 1px solid var(--light-gray-border-color);
}

.link__sliding__tab__header__wrapper .link__sliding__tab__header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 32px;
  position: relative;
}

.link__sliding__tab__header .link__sliding__tab__lhs {
  display: flex;
  align-items: center;
  height: 100%;
  flex: 1 1;
}

.link__sliding__tab__header .link__sliding__tab__rhs {
  display: flex;
  align-items: center;
  height: 100%;
  /* position: relative; */
}

.link__sliding__tab__rhs .sliding__tab__right__header__pan {
  height: 100%;
}

.link__sliding__tab__wrapper .link__sliding__tab__body__wrapper {
  width: 100%;
  display: flex;
  flex: 1 1;
}

.link__sliding__tab__body__wrapper,
.link__sliding__tab__body {
  width: 100%;
  display: flex;
  flex: 1 1;
}

.link__sliding__tab__header .link__sliding__tab {
  width: 106px;
  height: 32px;
  border-radius: var(--border-radius-12);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  z-index: 2;
}

.link__sliding__tab__header .link__sliding__tab:not(:last-child) {
  margin-right: 16px;
}

/* .link__sliding__tab__header
  .link__sliding__tab.link__sliding__tab__inactive:hover {
  background-color: var(--light-blue);
} */

.link__sliding__tab .link__sliding__tab__name {
  font-family: var(--gibson-semibold);
  font-size: var(--font-12);
  color: var(--white);
  word-break: break-all;
  padding: 0px 8px;

}

.whiteSpaceNoWrap {
  white-space: nowrap;
}

.link__sliding__tab__header .link__sliding__tab__indicator {
  width: 106px;
  height: 32px;
  border-radius: var(--border-radius-12);
  background-color: var(--orange);
  position: absolute;
  left: 0;
  transition: left 0.6s ease;
  z-index: 1;
}

.link__sliding__tabs__tab__pane {
  width: 100%;
  height: 100%;
}
.share__file__folder__popup__container {
  width: 535px;
  padding: 16px;
  border-radius: 20px;
  background: #2a2e43 0% 0% no-repeat padding-box;
  margin: auto;
  color: white;
}

.share__file__folder__popup__header {
  display: flex;
  justify-content: space-between;
  position: relative;
  font-family: var(--gibson-semibold);
  font-size: var(--font-24);
  border-bottom: 1px solid var(--gray);
  padding-bottom: 8px;
}

.share__file__folder__popup__close__icon {
  right: 0;
  cursor: pointer;
  position: absolute;
}

.shared__with__container {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
}

.shared__with__container p {
  font-size: var(--font-16);
  font-family: var(--gibson-semibold);
  text-transform: uppercase;
  margin-top: 4px;
}

.shared__with__container button {
  background-color: transparent;
  border: 1px solid var(--red);
  font-size: var(--font-12);
  font-family: var(--gibson-semibold);
  border-radius: var(--border-radius-20);
  width: 117px;
  padding: 2px;
  color: var(--dark-text-color);
}

.shared__user__container {
  width: 100%;
  height: 169px;
  background-color: var(--transparent-black);
  border-radius: var(--border-radius-12);
  margin-top: 16px;
  position: relative;
  overflow: scroll;
}

.shared__popup__button__container {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.shared__popup__button__container button {
  border-radius: var(--border-radius-12);
  border: 1px solid var(--gray);
  font-family: var(--gibson-semibold);
  font-size: var(--font-13);
  width: 80px;
  height: 44px;
}

.cancel__button {
  color: var(--white);
  background-color: var(--gray);
  margin-right: 8px;
}

.done__button {
  color: var(--white);
  background-color: var(--green);
}

.shared__user__container .fixed__text__container {
  display: flex;
  justify-content: space-between;
  padding: 16px;
}

.shared__user__container .fixed__text__container .normal__text {
  display: flex;
}

.shared__user__container .text__container {
  display: block;
  margin-left: 10px;
}

.shared__user__container .text__container p {
  color: var(--white) !important;
  font-size: var(--font-14);
  font-family: var(--gibson-light);
  line-height: 20px;
}

.contact__info__shared__student {
  display: inline-flex;
  width: 100%;
  position: relative;
  align-items: center;
}

.student__shared__personal {
  font-family: var(--poppins-regular);
  font-size: var(--font-14);
  color: var(--white);
  width: -webkit-fill-available !important;
  margin-top: 4px;
  position: relative;
}

.student__shared__name {
  text-align: left;
}

.student__shared__detail__contact {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 20px;
}

.toggle__button {
  display: flex;
  justify-content: flex-end;
}

.shared__user__container .switch input {
  position: absolute;
  opacity: 0;
}

.shared__user__container .switch {
  display: inline-block;
  font-size: var(--font-20);
  height: 6px;
  width: 26px;
  background: #bdb9a6;
  border-radius: 1em;
  cursor: pointer;
}

.shared__user__container .switch div {
  height: 17px;
  width: 17px;
  border-radius: 1em;
  background: var(--red) !important;
  box-shadow: 0 0.1em 0.3em rgb(0 0 0 / 30%);
  transition: all 300ms;
  border: 3px solid var(--white);
  position: relative;
  top: -6px;
  left: -6px;
}

.shared__user__container .switch input:checked+div {
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
  background: var(--green) !important;
}
.selected__user__chip__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 26px;
  border-radius: var(--border-radius-12);
  /* max-width: 190px; */
  width: 150px;
  margin: 3px 5px;
}

.selected__user__chip__container.selected__correct {
  border: 1px solid var(--gray);
}

.selected__user__chip__container.selected__incorrect {
  background-color: var(--red);
}

.selected__user__name {
  font-size: var(--font-15);
  font-family: var(--gibson-semibold);
  white-space: nowrap;
  width: 115px;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 10px;
  margin-right: 5px;
}

.selected__user__chip__container.selected__correct .selected__user__name {
  color: var(--gray);
}

.selected__user__chip__container.selected__incorrect .selected__user__name {
  color: var(--white);
}

.delete__icon__container {
  width: 21px;
  height: 21px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 3px;
}

.selected__user__chip__container.selected__correct .delete__icon__container {
  background-color: var(--gray);
}

.selected__user__chip__container.selected__incorrect .delete__icon__container {
  background-color: var(--white);
}

.selected__user__chip__container.selected__incorrect
  .delete__icon__container
  svg
  path {
  fill: var(--light-blue);
}

.input__box__and__suggestion__list__container {
  position: relative;
  width: 100%;
  margin-top: 10px;
  min-height: 52px;
}

.input__and__teacher__count__container {
  background-color: var(--white);
  border-radius: var(--border-radius-12);
  padding: 5px 10px;
  position: relative;
  z-index: 11;
}

.suggestion__input__container {
  display: flex;
  flex-wrap: wrap;
  max-height: 120px;
  overflow-y: auto;
  align-content: flex-start;
  scrollbar-width: thin;
}

.auto__suggestion__input {
  width: 100%;
  resize: none;
  height: 30px;
  flex: 1 1;
  display: flex;
  /* flex-basis: 100%; */
  border: none;
  line-height: 30px;
  color: var(--gray);
  font-size: var(--font-15);
  font-family: var(--gibson-semibold);
  margin: 0 5px;
  overflow-x: scroll;
}

.auto__suggestion__input::-webkit-scrollbar {
  display: none !important;
}

.input__and__teacher__count__container
  .teacher__count__container
  .teacher__count__text {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  font-size: var(--font-11);
  color: var(--gray);
  font-family: var(--poppins-light);
}

.suggestion__list__container {
  background-color: var(--light-blue);
  border-bottom-left-radius: var(--border-radius-12);
  border-bottom-right-radius: var(--border-radius-12);
  max-height: 250px;
  overflow-x: hidden;
  overflow-y: auto;
  margin-top: -10px;
  position: absolute;
  width: 100%;
  z-index: 10;
  /* height: 0px; */
  height: auto;

  padding: 0px;
  transition: all 0.6s ease;
}

.suggestion__list__container.active {
  padding-top: 20px;
  padding-bottom: 5px;
  height: auto;
}

/* placeholder */

.input__and__teacher__count__container .auto__suggestion__input::-webkit-input-placeholder {
  color: var(--gray);
}

.input__and__teacher__count__container .auto__suggestion__input::placeholder {
  color: var(--gray);
}

.suggestion__tile__container {
  margin: 5px 0;
  display: flex;
  align-items: center;
  padding: 5px 10px;
}

.suggestion__tile__container.hovered {
  background-color: var(--light-transparent-bg-grey);
}

.suggestion__tile__container .details__container {
  justify-content: space-between;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
}

.suggestion__tile__container .details__container .suggestion__left__container {
  display: flex;
  align-items: center;
  height: 100%;
}

.suggestion__left__container .name__and__email__container {
  display: flex;
  flex-direction: column;
  margin: 0 10px;
  height: 100%;
}

.suggestion__tile__container .details__container .suggestion__right__container {
  display: flex;
  align-items: flex-end;
  height: 40px;
}

.suggestion__left__container .name__and__email__container .name,
.suggestion__left__container .name__and__email__container .email,
.suggestion__right__container .phone {
  font-size: var(--font-14);
  color: var(--white);
  font-family: var(--poppins);
  line-height: 20px;
}

.suggestion__left__container .name__and__email__container .name{
  text-align: left;
}

.modalWrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  padding: 20px;
  z-index: 1000;
}
.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99;
  -webkit-backdrop-filter: blur(4px) brightness(90%);
          backdrop-filter: blur(4px) brightness(90%);
}

.acc_del_success_container{
 
  display: flex;
  flex-direction: column;
  border: 1px;
  box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.16);
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 99;
    background: #3C3C58;
    padding: 20px;
    border-radius: 15px;
    text-align: center;
    color: white;
    width: 350px;
    height: 250px;
}
#asdfgr{
    left: 0;
}
.Account_Deleted_Successfully{
    font-size: 29px;
    font-family: var(--gibson-semibold);
}
.otp_text_container{
    font-family: var(--poppins);
    margin-top: 10px;
}
.success_tick_img{
    height: 60px;
    margin: 20px;
}
* { /* Safari/Chrome, other WebKit */ /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
  margin: 0;
  padding: 0;
}

.containerView {
  height: 100%;
}

.containerView.min-dimensions {
  min-width: var(--screen-min-width);
  min-height: var(--screen-min-height);
}

/* for removing underline from all <a> tags and <Link> tags */
a {
  text-decoration: none;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background: transparent;
}

::-webkit-scrollbar-track {
  background: #edece9;
}

::-webkit-scrollbar-thumb {
  background: #d3d1cb;
}

::-webkit-scrollbar-thumb:hover {
  background: #9e9e9e;
}

.error_boundary_container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}

.error__text__container {
  position: relative;
  top: -120px;
}

.error__header {
  font-family: var(--gibson-semibold);
  color: var(--light-blue);
  text-align: center;
  font-size: var(--font-40);
}

.error__paras__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: var(--gibson);
  color: var(--dark-grey);
  font-size: var(--font-24);
}

.error__buttons__container {
  display: flex;
  justify-content: center;
  position: relative;
  top: -95px;
  left: -14px;
  width: 647px;
}

.error__buttons__container > div {
  display: flex;
  width: 360px;
  justify-content: space-between;
}

.go__back__button {
  padding: 19px 49px 18px 51px;
  font-size: 15px;
  font-family: var(--gibson-semibold);
  color: white;
  cursor: pointer;
  background-image: linear-gradient(to bottom, var(--light-orange-gradient-start), var(--light-orange-gradient-stop));
  border-radius: 14px;
  text-align: center;
}

.contact__support__button {
  padding: 19px 15px 18px 16px;
  color: white;
  font-size: 15px;
  font-family: var(--gibson-semibold);
  background-color: var(--gray);
  border-radius: 14px;
  text-align: center;
}

.support__link:hover {
  text-decoration: none;
}
