@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css");

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6,
label,
p {
  margin: 0;
  padding: 0;
  line-height: 1;
}

:root {
  --header-light-gray: #808080;
  --white: #ffffff;
  --light-white: #f4f4f4;
  --blue: #0000ff;
  --matt-blue: #5e5b74;
  --blue-gradient-start: #3c3c58;
  --blue-gradient-stop: #1c1c36;
  --dark-blue: #2a2e43;
  --darker-blue: #101b4f;
  --light-blue: #454f63;
  --cal-text: #52575d;
  --cal-unactive-text: #d0dbe5;
  --light-gray1: #e6e6e6;
  --gray3: #b8b8b8;
  --gray2: #99a1a7;
  --grey1: #808080;
  --gray: #78849e;
  --gray-dark: #383d4a;
  --purple: #c840e9;
  --pink: #ff5e99;
  --red: #ff4f67;
  --red-5: rgba(255, 79, 103, 0.1);
  --gray-border: #979797;
  --light-red: #e6e5e5;
  --light-red-border: #eeeeee;
  --matte-orange: #ff9057;
  --yellow: #ffb900;
  --yellow-5: rgba(255, 185, 0, 0.1);
  --light-orange-gradient-start: #fdbb5c;
  --light-orange-gradient-stop: #ffa015;
  --green: #17c8af;
  --dark-green: #00b59c;
  --lime-green: #59c260;
  --sky-blue: #3acce1;
  --terminal-blue: #5773ff;
  --forest-green: #008a76;
  --darker-green: #008a76;
  --orange: #fb963c;
  --orange-3: rgba(252, 115, 24, 0.2);
  --orange-2: #fc7318;
  --light-orange: #f8b757;
  --view-all-blue-button: #6672fb;
  --border-box-shadow-color: #d1cccc;
  --dark-orange-gradient-start: #fb9e58;
  --dark-orange-gradient-stop: #ff913e;
  --background-gray: rgba(235, 232, 232, 0.5);
  --light-gray: #f7f8fa;
  /* used in searchbar and activity icon background color */
  --seperator-color: #bbc5d5;
  /* used in searchbar seperator background */
  --searchbar-text-color: #354052;
  --light-transparent-bg-grey: rgba(120, 132, 158, 0.15);
  --dark-text-color: #959dad;
  --custom-tabview-tab-seperator: #f4f4f6;
  --people-attendance-average-table-legend: #e7ecf5;
  --light-border-color: #f5f5f5;
  --light-border-color2: #f7f7f7;
  --light-border-color-with-opacity: rgba(245, 245, 245, 0.13);
  --table-header-bg-color: rgba(227, 233, 239);
  --table-hover-bg-color: rgba(234, 242, 255);
  --pagination-caret-light-color: #e3e9ef;
  --details-table-seperator-color: #ebf2f7;
  --calendar-header-day-name-color: #b3b8bd;
  --calendar-header-date-color: #52575d;
  --calendar-toolbar-prev-next-color: #333333;
  --add-class-card-bg-color: #c4cad7;
  --copy-link-container-bg-color: rgba(60, 60, 88, 0.5);
  --light-gray-border-color: #707070;
  --light-gray-border-color-opacity: rgba(112, 112, 112, 0.4);
  --reminder-popup-dispription-bg-color: rgb(211, 211, 211);
  --submission-card-bg-color: #e1e1e1;
  --whiteboard-border-color: #e0dede;
  --weekly-calendar-today-column-background-color: rgba(0, 181, 156, 0.2);
  --light-gray-text-color: rgba(120, 132, 158, 0.56);
  --month-calendar-popup-day-detail-box-border-color: rgba(133, 146, 173, 0.17);
  --grid-icon-inactive-color: #b5bdcf;
  --transparent-white: rgba(255, 255, 255, 0.9);
  --transparent-black: rgba(0, 0, 0, 0.3);
  --transparent-black-2: rgba(0, 0, 0, 0.5);
  --black: #000000;
  --black-1: #1D1D2F;
  --start-class-video-button-color: #353a50;
  --settings-tab-default-button-border-color: #bebebe;
  --login-page-background-color: #f5f5f7;
  --landing-page-section-header-color: #1d1d1d;
  --dark-grey: #a5adbb;
  --light-grey: #b1acac;
  --light-gray-2: #ebe8e8;
  --light-gray-3: #d8d9db;
  --light-gray-4: #e8eaef;
  --light-gray-5: #c8c9cb;
  --light-gray-6: #cccccc;
  --light-dark-gray: linear-gradient(#92a1c4, #4a5365);
  --transparent-blue: rgba(69, 79, 99, 0.1);
  --translucent-gray: rgba(120, 132, 158, 0.5);
  --transparent-blue-text: rgba(39, 39, 65, 0.56);

  --header-gradient: linear-gradient(to top,
      rgba(60, 60, 88, 0.6),
      rgba(28, 28, 54, 0.6));
  --light-blue: rgb(69, 79, 99);
  --blue-time-indicator: #3497fd;
  --input-placeholder: rgba(120, 132, 158, 0.56);
  --blue-button: #508ff4;
  --icon-button-default-bg: rgba(42, 46, 67, 0.3);
  --icon-button-hover-bg: rgba(42, 46, 67, 0.5);
  --quiz-blue-gradient: linear-gradient(90deg, #68dae0 0%, #2071ff 100%) 0% 0% no-repeat;
  --gray-radial-shadow: radial-gradient(50% 50% at 50% 50%,
      #ffffff 0%,
      #fdfdfd 59.4%,
      #f6f6f7 80.8%,
      #eaebec 96%,
      #e6e7e8 100%);
  --pink: #ff5e99;

  /* font sizes */
  --font-8: 8px;
  --font-9: 9px;
  --font-10: 10px;
  --font-11: 11px;
  --font-12: 12px;
  --font-13: 13px;
  --font-14: 14px;
  --font-15: 15px;
  --font-16: 16px;
  --font-20: 20px;
  --font-21: 21px;
  --font-22: 22px;
  --font-18: 18px;
  --font-23: 22px;
  --font-23: 23px;
  --font-24: 24px;
  --font-25: 25px;
  --font-26: 26px;
  --font-28: 28px;
  --font-29: 29px;
  --font-30: 30px;
  --font-31: 31px;
  --font-32: 32px;
  --font-33: 33px;
  --font-34: 34px;
  --font-35: 35px;
  --font-36: 36px;
  --font-40: 40px;
  --font-42: 42px;
  --font-49: 49px;

  /* font-family */
  /* when uncommenting gibson light and gibson bold, do uncomment the imports in index.html as well  */
  /* --gibson-light: "GibsonLight"; */
  /* --gibson-bold: "GibsonBold"; */

  --gibson: "GibsonRegular";
  --gibson-light: "GibsonLight";
  --gibson-semibold: "GibsonSemiBold";
  --poppins: "PoppinsRegular";
  --poppins-light: "PoppinsLight";
  --poppins-medium: "PoppinsMedium";
  --poppins-semibold: "PoppinsSemiBold";
  --poppins-bold: "PoppinsBold";
  --poppins-extrabold: "PoppinsExtraBold";
  --avenir-light: "AvenirLight";

  /* border-radius */
  --border-radius-3: 3px;
  --border-radius-4: 4px;
  --border-radius-5: 5px;
  --border-radius-6: 6px;
  --border-radius-8: 8px;
  --border-radius-9: 9px;
  --border-radius-10: 10px;
  --border-radius-12: 12px;
  --border-radius-13: 13px;
  --border-radius-15: 15px;
  --border-radius-16: 16px;
  --border-radius-19: 19px;
  --border-radius-18: 18px;
  --border-radius-21: 21px;
  --border-radius-22: 22px;
  --border-radius-27: 27px;
  --border-radius-20: 20px;
  --border-radius-26: 26px;
  --border-radius-30: 30px;
  --border-radius-32: 32px;
  --border-radius-35: 35px;
  --border-radius-40: 40px;
  --border-radius-50: 50px;
  --border-radius-round: 50%;

  /* box-shadow */
  --schedule-lecture-reminder-box-shadow: 0px 4px 16px #455b6314;
  --assignment-header-box-shadow: 0px 12px 16px #455b6314;
  --tabview-shadow: 0px 0px 16px rgba(0, 0, 0, 0.16) !important;
  --cards-shadow: 0px 0px 16px rgba(0, 0, 0, 0.16) !important;
  --border-box-shadow: inset 0px 0px 10px #d1cccc !important;
  --create-tab-icon-shadow: 0px 12px 16px rgba(69, 91, 99, 0.1) !important;
  --cards-shadow-light: 0px 4px 16px rgba(69, 91, 99, 0.08) !important;
  --live-class-wide-tab-shadow: 0px 3px 42px rgba(0, 0, 0, 0.16) !important;
  --live-class-narrow-tab-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16) !important;
  --batches-activity-shadow: 0px 8px 16px rgba(0, 0, 0, 0.16) !important;
  --navigation-bar-shadow: 0px 20px 30px rgba(0, 0, 0, 0.06) !important;
  --calendar-popup-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08) !important;
  --live-class-timer-shadow: 0px 12px 16px rgba(120, 132, 158, 0.13) !important;
  --student-live-class-notebook-shadow: 0px 3px 32px rgba(0, 0, 0, 0.16) !important;
  --login-btn-box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.16) !important;
  --logininput-box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.16) !important;
  --mobile-login-btn-box-shadow: 0px 5px 16px rgba(0, 0, 0, 0.16) !important;
  --icon-bg-box-shadow: 0px 0px 45px rgba(0, 0, 0, 0.16) !important;
  --mybag-resources-card-shadow: 0px 7px 20px rgba(0, 0, 0, 0.16) !important;
  --edit-lecture-popup-box-shadow: 0px 10px 40px rgb(2 2 2 / 11%) !important;
  --class-description-input-container-box-shadow: 0px 5px 11px rgb(69 91 99 / 8%) !important;
  --class-modal-content2-box-shadow: 0px 10px 40px rgba(2, 2, 2, 0.11) !important;
  --edit-lecture-popup-box-shadow: 0px 10px 40px rgb(2 2 2 / 11%) !important;
  --class-signin-inputs-error-box-shadow: 0px 2px 11px var(--red) !important;
  --class-signin-inputs-box-shadow: 0px 5px 11px rgba(69, 91, 99, 0.08) !important;
  --class-signin-inputs2-box-shadow: 0px 5px 11px rgba(69, 91, 99, 0.08) !important;
  --class-signin-inputs3-box-shadow: 0px 5px 11px rgba(69, 91, 99, 0.08) !important;
  --input-and-teacher-count-containe-error-box-shadow: 0px 2px 11px var(--red) !important;
  --pending-request-count-box-shoadow: 0 2px 4px #78849e;
  --classroom-tile-container-shadow: 0px 3px 42px rgba(0, 0, 0, 0.16) !important;
  --big-link-logo-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06) !important;
  --content-header-shadow: 0px 10px 18px #00000029 !important;
  --thick-button-shadow: 0px 4px 8px var(--gray) !important;
  --resource-tile-background-shadow-box: 0 16px 24px rgb(29 84 180 / 12%) !important;
  --live-quiz-tab-header-shadow: 0px 15px 10px rgba(13, 23, 35, 0.12) !important;
  --live-quiz-tab-content-shadow: 0px 4px 16px rgba(69, 91, 99, 0.1) !important;
  --table-action-menu-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25) !important;

  --calendar-active-date-shadow: 0px 5px 25px rgba(82, 87, 93, 0.1) !important;
  --week-selectore-calender-shadow: 0px 4px 18px rgba(0, 0, 0, 0.16) !important;
  --overview-card-shadow: 0px 8px 16px rgba(0, 0, 0, 0.16) !important;
  /* others */
  --chat-tablist-height: 43px;
  --right-column-width: 294px;
  --max-width-landing-page: 1800px;
  --custom-tabview-tab-width: 160px;
  /* do not use this value anywhere to define the width, as this value will be modfied in run time by JS */
  --resizable-layout-right-column-width: 294px;
  --link-navigation-bar-height: 70px;
  --navigation-sidebar-width: 70px;
  --navigation-layout-horizontal-padding: 24px;
  --live-class-control-panel-height: 128px;
  --live-class-lhs-tab-header-height: 43px;
  --navigation-layout-top-padding: 25px;
  --screen-min-height: 600px;
  --screen-min-width: 1000px;

  /* zindex in the descending order of priority */
  --zindex-alert: 10040;
  --zindex-loader: 10000;
  --zindex-popup-content: 1060;
  --zindex-popup-backdrop: 1040;
}

html {
  height: 100%;
  font-size: 16px;
}

body {
  width: 100%;
  min-height: 100vh;
  background-image: linear-gradient(var(--white), var(--light-gray-2));
  background-attachment: fixed;
}

#root {
  height: 100%;
  width: 100%;
  position: absolute;
}

button:focus {
  outline: 0;
}

input:focus {
  outline: 0;
}

textarea:focus {
  outline: 0;
}

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.hide__scrollbar {
  scrollbar-width: none;
  /* mozilla */
}

.hide__scrollbar::-webkit-scrollbar {
  /* chrome, edge */
  display: none;
  -ms-overflow-style: none;
}

.dark__gradient {
  background-image: linear-gradient(var(--blue-gradient-start),
      var(--blue-gradient-stop));
}

.bg__white {
  background-color: var(--white);
}

.blur_background_screen {
  filter: blur(8px);
  -webkit-filter: blur(8px);
}

.current__scrollbar {
  position: fixed;
  width: 100%;
}

/* random iframe is being added on top of the page and blocks the click events*/
body>iframe,
.os-content>iframe {
  pointer-events: none;
}

/* styles for preview library - on no render */
#no-renderer-download {
  position: absolute;
  bottom: 1em;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--blue-button);
  color: white;
  text-transform: uppercase;
  font-family: var(--gibson-semibold);
  white-space: nowrap;
  padding: 1.2em 2em;
  width: auto;
  border-radius: var(--border-radius-12);
  text-decoration: none;
}

/* Animations */

.animated {
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes wobble {
  0% {
    -webkit-transform: translateX(0%);
  }

  15% {
    -webkit-transform: translateX(-25%) rotate(-5deg);
  }

  30% {
    -webkit-transform: translateX(20%) rotate(3deg);
  }

  45% {
    -webkit-transform: translateX(-15%) rotate(-3deg);
  }

  60% {
    -webkit-transform: translateX(10%) rotate(2deg);
  }

  75% {
    -webkit-transform: translateX(-5%) rotate(-1deg);
  }

  100% {
    -webkit-transform: translateX(0%);
  }
}

@keyframes wobble {
  0% {
    transform: translateX(0%);
  }

  15% {
    transform: translateX(-25%) rotate(-5deg);
  }

  30% {
    transform: translateX(20%) rotate(3deg);
  }

  45% {
    transform: translateX(-15%) rotate(-3deg);
  }

  60% {
    transform: translateX(10%) rotate(2deg);
  }

  75% {
    transform: translateX(-5%) rotate(-1deg);
  }

  100% {
    transform: translateX(0%);
  }
}

.wobble {
  -webkit-animation-name: wobble;
  animation-name: wobble;
}