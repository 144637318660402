
.initialAvatar_container{
    width: 17px;
    height: 17px;
    border-radius: 8.5px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #78849E;
}

.child-view {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.parent-view {
  height: 0;
  width: 100%;
  position: relative;
}