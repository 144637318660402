.Start_Now_Popup_Mic_Bars_Container {
  display: flex;
  margin-top: 5px;
  align-items: flex-end;
}

.mic-icon-start-now {
  min-height: 33px;
  max-height: 33px;
  min-width: 33px;
  max-width: 33px;
  cursor: pointer;
}
