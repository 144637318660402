.parent-view-16x9 {
  width: 100%;
  padding-top: 56.25%;
  height: 0;
  position: relative;
}

.view-16x9 {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.parent-view {
  height: 0;
  width: 100%;
  position: relative;
}

.child-view {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.neutral-view {
  width: 100%;
  height: 100%;
}

.mobile-screen-view {
  height: 100%;
  position: absolute;
  top: 0;
  /* left: 38%; */
  width: 100%;
}
