.link__sliding__tab__wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.link__sliding__tab__wrapper .link__sliding__tab__header__wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  height: calc(32px + 14px);
  padding: 7px 0;
}

.link__sliding__tab__wrapper .link__sliding__tab__header__wrapper.with__separator {
  border-top: 1px solid var(--light-gray-border-color);
  border-bottom: 1px solid var(--light-gray-border-color-opacity);
}

.link__sliding__tab__wrapper .link__sliding__tab__header__wrapper.link__sliding__tab__without__bottom__separator {
  border-bottom: none;
}

.link__sliding__tab__wrapper.with__top__separator {
  border-top: 1px solid var(--light-gray-border-color);
}

.link__sliding__tab__header__wrapper .link__sliding__tab__header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 32px;
  position: relative;
}

.link__sliding__tab__header .link__sliding__tab__lhs {
  display: flex;
  align-items: center;
  height: 100%;
  flex: 1;
}

.link__sliding__tab__header .link__sliding__tab__rhs {
  display: flex;
  align-items: center;
  height: 100%;
  /* position: relative; */
}

.link__sliding__tab__rhs .sliding__tab__right__header__pan {
  height: 100%;
}

.link__sliding__tab__wrapper .link__sliding__tab__body__wrapper {
  width: 100%;
  display: flex;
  flex: 1;
}

.link__sliding__tab__body__wrapper,
.link__sliding__tab__body {
  width: 100%;
  display: flex;
  flex: 1;
}

.link__sliding__tab__header .link__sliding__tab {
  width: 106px;
  height: 32px;
  border-radius: var(--border-radius-12);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  z-index: 2;
}

.link__sliding__tab__header .link__sliding__tab:not(:last-child) {
  margin-right: 16px;
}

/* .link__sliding__tab__header
  .link__sliding__tab.link__sliding__tab__inactive:hover {
  background-color: var(--light-blue);
} */

.link__sliding__tab .link__sliding__tab__name {
  font-family: var(--gibson-semibold);
  font-size: var(--font-12);
  color: var(--white);
  word-break: break-all;
  padding: 0px 8px;

}

.whiteSpaceNoWrap {
  white-space: nowrap;
}

.link__sliding__tab__header .link__sliding__tab__indicator {
  width: 106px;
  height: 32px;
  border-radius: var(--border-radius-12);
  background-color: var(--orange);
  position: absolute;
  left: 0;
  transition: left 0.6s ease;
  z-index: 1;
}

.link__sliding__tabs__tab__pane {
  width: 100%;
  height: 100%;
}